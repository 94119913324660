.home-container {
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #252525;
}

.hero {
  max-width: 100%;
  height: 100vh;
}

.logo-container {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.logo {
  font-size: 1.2em;
  font-weight: 700;
  color: #ff6000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.user-container {
  padding: 0px 20px;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
}
