@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  background-color: #252525;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input:-webkit-autofill {
  background-color: blueviolet !important;
  color: brown !important;
}

input[type='number'] {
  -moz-appearance: textfield;
}
input,
select,
textarea {
  @apply rounded border p-2  outline-none ring-indigo-300  focus:ring;
}

.container {
  width: 100vw;
}
.card {
  @apply mb-5 block rounded-lg border border-gray-200 shadow-md;
}

.primary-button {
  @apply mt-3 rounded border-4 border-[#4F46E5] bg-[#4F46E5] px-4 py-2 shadow outline-none hover:bg-[#4F46E5] active:bg-[#4F46E5];
}

.link {
  cursor: pointer;
  color: blue;
}

/* MUI */
input:focus,
select:focus,
textarea:focus {
  box-shadow: none;
}

.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*_____________________________________________*/
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}
