.dashboard-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;

  height: auto;
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: start;
  width: 20vw;
  background-color: #252525;
  min-height: 100vh;
  height: 100%;
}
.hidden {
  width: 0vw;
  display: none;
}
.top {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  padding: 10px 10px;
}
.username {
  color: azure;
  text-align: center;
  width: 100%;
}
.content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 80vw;
  background-color: rgb(73, 73, 73);
  min-height: 100vh;
  height: auto;
  padding: 10px 40px;
}
.full {
  width: 100vw;
}

.menu {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
}

.camera-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px 40px;
}
.overlay {
  background-color: aliceblue;
  width: 512px;
  height: 512px;
  position: absolute;
  opacity: 0.3;
  clip-path: ellipse(100px 140px at 256px 200px);
  z-index: 999;
}

.train-progress {
  color: #fff;
  font-size: 25px;
  width: 100%;
}
